var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"justify-center px-0 pt-0"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"\n      mx-10 mx-sm-12 mx-md-14\n      px-md-0\n      mb-10\n      pt-md-8\n      d-flex\n      justify-sm-space-between\n    "},[_c('h3',{staticClass:"text-bold primary--text",staticStyle:{"font-style":"normal","font-weight":"bold","font-size":"32px","line-height":"39px"}},[_vm._v(" Forms ")]),_c('v-spacer'),_c('create-form-modal',{ref:"formDialog"})],1):_vm._e(),[(_vm.isLoadingEntries)?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"60vh","width":"100%"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-container',{staticClass:"pt-8 px-0"},[_c('div',{staticClass:"d-flex align-center px-10"},[_c('span',{staticClass:"d-inline-block font-weight-bold text-capitalize my-auto px-6",staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v(" "+_vm._s(_vm.formName)+" ")])]),_c('v-card',{staticClass:"mx-auto mb-md-10 mt-md-2",attrs:{"flat":"","elevation":"6","width":"93%","min-height":"674"}},[_c('v-card',{staticClass:"d-flex align-center mx-sm-0 py-0 py-md-2",attrs:{"width":"100%","height":"58px","flat":"","color":"#f4f4f4"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"text":"","rounded":"","large":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" Back ")],1),_c('v-spacer'),_c('div',[_c('v-autocomplete',{staticClass:"px-8",attrs:{"dense":"","hide-details":"true","items":_vm.headers,"multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('v-chip',{staticClass:"mb-2",attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedColumns.length - 2)+" others)")]):_vm._e()]}}]),model:{value:(_vm.selectedColumns),callback:function ($$v) {_vm.selectedColumns=$$v},expression:"selectedColumns"}})],1),_c('download-csv',{staticClass:"btn btn-default",attrs:{"data":_vm.dataEntries}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"export-btn mr-9 hover-btn",style:({
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }),attrs:{"outlined":"","color":"primary","elevation":"2"},on:{"click":_vm.alertCSVDownload}},[_c('span',{staticClass:"material-icons pr-1"},[_vm._v(" import_export ")]),_c('span',{staticClass:"primary--text exp_txt"},[_vm._v("Export")])])]}}])})],1)],1),_c('div',{staticClass:"ma-0 pa-0"},[[_c('v-data-table',{staticClass:"elevation-0",attrs:{"height":"100%","headers":_vm.selectedColumns,"items":_vm.displayedItems,"hide-default-footer":""},on:{"click:row":_vm.handleRowClick}})],(_vm.dataEntries && _vm.dataEntries.length > 0)?_c('div',{staticClass:"pagination__container px-8"},[_c('BasePagination',{attrs:{"current-page":_vm.currentPage,"visible-pages":_vm.visiblePageValue,"total-pages":_vm.totalPages},on:{"page-changed":_vm.handlePageChange}})],1):_vm._e()],2)],1)],1)],_c('div',[_c('v-navigation-drawer',{staticClass:"navigator",attrs:{"hide-overlay":true,"width":"390px","right":"","temporary":"","app":""},model:{value:(_vm.entriesDrawer),callback:function ($$v) {_vm.entriesDrawer=$$v},expression:"entriesDrawer"}},[_c('SingleEntriesDetails',{attrs:{"entryId":_vm.singleEntryId}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }